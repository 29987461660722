<template>
    <layout></layout>
</template>

<script>
import Layout from '@/views/layouts/horizontal';

export default {
    name: "index",

    components: { Layout }
}
</script>

<style scoped>

</style>
